import tinycolor from 'tinycolor2'
import {
  COMPANY_MACROTREND,
  COMPANY_MEGATREND,
  COUNTRY,
  INDUSTRY,
  INNOVATION_TYPE,
  MACROTREND,
  MEGATREND,
  REGION,
  TRENDRADAR_DRAFT,
  TRENDRADAR_PUBLISHED
} from '@/model/types'

export const FACET_TYPE_PARENT_CHILD = 1
export const FACET_TYPE_STANDARD = 2

export class FacetEntry {
  constructor (id, title, count) {
    this.id = id
    this.title = title
    this.count = count || 0
    this.filter = 'undefined'
    this.color = 'primary'
    this.children = {}
    this.active = false
    this.visible = true
    this.parent = null
  }
}

export class MegatrendFacetEntry extends FacetEntry {
  constructor (element) {
    super(element.node.id, element.node.translations[0].title, element.count)
    this.filter = 'megatrends'
    this.color = element.node.color
  }
}

export class MacrotrendFacetEntry extends FacetEntry {
  constructor (element) {
    super(element.node.id, element.node.translations[0].title, element.count)
    this.parent = element.node.megatrend ? element.node.megatrend.id : null
    this.filter = 'macrotrends'
    this.color = tinycolor(element.node.megatrend ? element.node.megatrend.color : 'black').lighten(10).toHexString()
    this.deprecated = element.node.deprecated
    this.successor = element.node.successor
    this.type = element.node.__typename
  }
}

export class RegionFacetEntry extends FacetEntry {
  constructor (element) {
    super(element.node.id, element.node.translations[0].title, element.count)
    this.filter = 'regions'
    this.color = '#546161'
  }
}

export class CountryFacetEntry extends FacetEntry {
  constructor (element) {
    super(element.node.id, element.node.translations[0].title, element.count)
    this.parent = element.node.region ? element.node.region.id : null
    this.filter = 'countries'
    this.color = '#81989F'
  }
}

export class IndustryFacetEntry extends FacetEntry {
  constructor (element) {
    super(element.node.id, element.node.translations[0].title, element.count)
    this.filter = 'industries'
    this.color = '#504F62'
  }
}

export class InnovationTypeFacetEntry extends FacetEntry {
  constructor (element) {
    super(element.node.id, element.node.translations[0].title, element.count)
    this.filter = 'innovationType'
    this.color = '#9A9B9A'
  }
}

export class TrendradarFacetEntry extends FacetEntry {
  constructor (element) {
    super(element.node.id, element.node.title, element.count)
    this.filter = 'trendradars'
    this.color = '#ef10ef'
  }
}

export class SdgFacetEntry extends FacetEntry {
  constructor (element) {
    super(element.node.id, element.node.translations[0].title, element.count)
    this.filter = 'sdgs'
    this.color = '#1620d1'
  }
}

class Facet {
  constructor (title, type) {
    this.type = type
    this.title = title
    this.elements = {}
  }

  add (facet) {
    this.elements[facet.id] = facet
  }

  addChild (facet) {
    if (typeof this.elements[facet.parent] === 'undefined') {
      // eslint-disable-next-line no-console
      console.warn('Parent facet not found', facet.id ? facet.id : 'undefined id')
      return
    }
    if (typeof facet.parent === 'undefined') {
      // eslint-disable-next-line no-console
      console.warn('Parent facet not defined', facet.id ? facet.id : 'undefined id')
      return
    }

    this.elements[facet.parent].children[facet.id] = facet
  }

  reset () {
    const self = this
    Object.keys(self.elements).map(k1 => {
      self.elements[k1].active = false
      self.elements[k1].visible = true
      Object.keys(self.elements[k1].children).map(k2 => {
        self.elements[k1].children[k2].visible = true
      })
    })
  }

  search (token) {
    this.reset()

    if (!token || token.length === 0) {
      return this.elements
    }

    const re = new RegExp(token, 'i')
    const self = this

    Object.keys(this.elements).map(k1 => {
      self.elements[k1].visible = self.elements[k1].title.search(re) > -1

      // self.elements[k1].visible = false

      Object.keys(self.elements[k1].children).map(k2 => {
        if (self.elements[k1].children[k2].title.search(re) > -1) {
          self.elements[k1].children[k2].visible = true
          self.elements[k1].visible = true
          self.elements[k1].active = true
        } else {
          self.elements[k1].children[k2].visible = false
        }
      })
    })

    return this.elements
  }
}

export class FacetFactory {
  static create (elements) {
    const trends = FacetFactory.createParentChildFacet('trends', 'megatrends', 'macrotrends', elements)
    const geo = FacetFactory.createParentChildFacet('regions', 'regions', 'countries', elements)
    const industries = FacetFactory.createStandardFacet('industries', 'industries', elements)
    const innovationTypes = FacetFactory.createStandardFacet('innovationTypes', 'innovationType', elements)
    const sdgs = FacetFactory.createStandardFacet('sdgs', 'sdgs', elements)

    if ('trendradars' in elements && elements.trendradars.length > 0) {
      const trendradars = FacetFactory.createStandardFacet('trendradars', 'trendradars', elements)

      return [trends, geo, industries, innovationTypes, sdgs, trendradars]
    }

    return [trends, geo, industries, innovationTypes, sdgs]
  }

  static createEntriesFromNodes (nodes) {
    const result = []

    nodes.forEach(node => {
      if (!node) {
        // eslint-disable-next-line no-console
        console.warn('Could not create entry from node (either undefined or null).')
        return
      }

      switch (node.__typename) {
      case REGION:
        // eslint-disable-next-line no-case-declarations
        const region = new RegionFacetEntry({
          node: node,
          count: 0
        })
        result.push(region)
        node.countries.nodes.forEach(subnode => {
          const country = new CountryFacetEntry({
            node: subnode,
            count: 0
          })
          region.children[country.id] = country
          result.push(country)
        })
        break
      case COUNTRY:
        result.push(new CountryFacetEntry({
          node: node,
          count: 0
        }))
        break
      case INDUSTRY:
        result.push(new IndustryFacetEntry({
          node: node,
          count: 0
        }))
        break
      case INNOVATION_TYPE:
        result.push(new InnovationTypeFacetEntry({
          node: node,
          count: 0
        }))
        break
      case MACROTREND:
      case COMPANY_MACROTREND:
        result.push(new MacrotrendFacetEntry({
          node: node,
          count: 0
        }))
        break
      case MEGATREND:
      case COMPANY_MEGATREND:
        // eslint-disable-next-line no-case-declarations
        const mega = new MegatrendFacetEntry({
          node: node,
          count: 0
        })
        result.push(mega)
        node.macrotrends.edges.forEach(subnode => {
          const macro = new MacrotrendFacetEntry({
            node: subnode.node,
            count: 0
          })
          mega.children[macro.id] = macro
          result.push(macro)
        })
        break
      case TRENDRADAR_DRAFT:
      case TRENDRADAR_PUBLISHED:
        result.push(new TrendradarFacetEntry({
          node,
          count: 0
        }))
        break
      }
    })

    return result
  }

  static createEntryInstance (key, data) {
    const map = {
      megatrends: MegatrendFacetEntry,
      macrotrends: MacrotrendFacetEntry,
      countries: CountryFacetEntry,
      regions: RegionFacetEntry,
      industries: IndustryFacetEntry,
      innovationType: InnovationTypeFacetEntry,
      trendradars: TrendradarFacetEntry,
      sdgs: SdgFacetEntry
    }

    if (!(key in map)) {
      return null
    }

    return new map[key](data)
  }

  static createParentChildFacet (title, parents, children, elements) {
    const container = new Facet(title, FACET_TYPE_PARENT_CHILD)

    if (!(parents in elements)) {
      // eslint-disable-next-line no-console
      console.error('Unknown parent', parents)
      return container
    }

    if (!(children in elements)) {
      // eslint-disable-next-line no-console
      console.error('Unknown children', children)
      return container
    }

    for (const data of elements[parents]) {
      const instance = FacetFactory.createEntryInstance(parents, data)
      if (instance !== null) {
        container.add(instance)
      }
    }

    for (const data of elements[children]) {
      const instance = FacetFactory.createEntryInstance(children, data)
      if (instance !== null) {
        container.addChild(instance)
      }
    }

    return container
  }

  static createStandardFacet (title, idx, elements) {
    const container = new Facet(title, FACET_TYPE_STANDARD)

    if (!(idx in elements)) {
      // eslint-disable-next-line no-console
      console.error('Unknown idx', idx)
      return container
    }

    for (const data of elements[idx]) {
      const instance = FacetFactory.createEntryInstance(idx, data)
      if (instance !== null) {
        container.add(instance)
      }
    }

    return container
  }
}

export const sortFacetMap = (hashmap) => Object.values(hashmap).sort((a, b) => a.title.localeCompare(b.title))
